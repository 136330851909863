import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Typography, } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from 'recharts';

import BaseWidget from './BaseWidget';

import { DashboardWidgetType, } from '../../../core/entities/dashboard-widget';

import { getCompanySensitiveDataCategoriesClassification, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function SensitiveDataCategoryReportWidget(props) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const formattedData = React.useMemo(() => {
    const total = data.reduce((prev, curr) => curr.amount + prev, 0);
    return data.map((item) => {
      return {
        ...item,
        value: item.amount,
        label: total > 0 ? `${(item.amount / total * 100).toFixed(0)}%` : '',
      };
    });
  }, [data]);


  async function getData() {
    setLoading(true);
    try {
      const { data, } = await getCompanySensitiveDataCategoriesClassification(company._id);
      setData(data.classification);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getData();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <Typography
        variant="body1"
        display="block"
        style={{
          color: '#54708C',
        }}
      >
        { t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.SENSITIVE_DATA_CATEGORY_REPORT}.description`) }
      </Typography>

      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <ResponsiveContainer
          width="100%"
          height="100%"
          minHeight={180}
        >
          <BarChart data={formattedData} >
            <XAxis
              dataKey="name"
              fontWeight="bold"
            />
            <Tooltip />

            <Bar
              dataKey="value"
              fill="#F94A93C9"
              name={t(`PAGES.DASHBOARD.quantity`) }
              radius={[10, 10, 0, 0]}
              minPointSize={12}
            >
              <LabelList
                dataKey="label"
                position="center"
                fill="#FFFFFF"
                fontWeight="bold"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </BaseWidget>
  );
}

export default SensitiveDataCategoryReportWidget;
