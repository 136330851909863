import React, { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useSelector, useDispatch, } from 'react-redux';

import { Icon, Notification, } from 'rsuite';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import BreadCrumbs from '../../components/Global/BreadCrumbs';
import Title from '../../components/Global/PageTitle';
import RiscAnalysisShow from '../../components/RiscoAnaliseComponent/RiscAnalisisShow';
import ActionsFilter from '../../components/RiscoAnaliseComponent/Components/ActionButtonHeaderAnaliseRisc';
import Dropdown from '../../components/Dropdown';
import CardMenuLeft from '../../components/Menus/CardMenuLeft';
import Paginator from '../../components/Paginator/Paginator';

import {
  listCompanyMeetings,
  notReunion,
  getRiscoNotResponsible,
  getRiscoByReunionID,
  listCompanyActions,
} from '../../services/api';
import { setItens, updateTitle, } from '../../features/matrizDeRisco';
import { selectCompany, } from '../../features/companySlice';
import { updateReunion, } from '../../features/reunionsSlice';
import { listUsers, selectUsers, } from '../../features/usersSlice';
import { setCurrentMeeting } from "../../features/planAction";

import { ACTION_STATUSES, } from '../../core/entities/action-plans';
import { TASK_STATUSES, } from '../../core/entities/checklist-tasks';
import { setGlobalMenuFixed } from '../../features/fixedMenuPages';
import PartialCustomMenuFixed from '../../components/CustomMenuFixed/PartialCustomMenuFixed';
import { InputAdornment, TextField } from '@mui/material';


const INITIAL_VALUES = Object.freeze({
  [ACTION_STATUSES.PENDING]: {
    values: ['Pendente', null],
    total: 0,
  },
  [ACTION_STATUSES.IN_PROGRESS]: {
    values: ['Em andamento'],
    total: 0,
  },
  [ACTION_STATUSES.CLOSED]: {
    values: ['Concluido'],
    total: 0,
  },
});


/**
 * Action plan page
 *
 * @returns Rendered page
 */
export default function ActionPlan({ progress = 'IN_PROGRESS' }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const [tabStatus, setTabStatus] = useState(progress);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [filteredActions, setFilteredActions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionsStatuses, setActionsStatuses] = useState(INITIAL_VALUES);
  const [search, setSearch] = useState('');
  // const [currentMeeting, setCurrentMeeting] = useState(null);
  const meetings = useSelector((state) => state.reunions.reunions);
  const actions = useSelector((state) => state.matriz.value);
  const loadingMatriz = useSelector((state) => state.matriz.loading);

  const users = useSelector(selectUsers);
  const company = useSelector(selectCompany);

  useEffect(() => {
    dispatch(setGlobalMenuFixed(false))
    return () => {
      dispatch(setGlobalMenuFixed(true))
    }
  }, []);

  async function initializeActions() {
    try {
      await getAllActions();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar ações do servidor!',
      });
    }
  }

  async function loadMeetings() {
    try {
      const { data, } = await listCompanyMeetings(company._id);
      dispatch(updateReunion(data));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar reuniões do servidor!',
      });
    }
  }

  async function initialize() {
    setLoading(true);
    dispatch(listUsers());
    //estas duas tem que ir para o contexto da aplicação;
    await Promise.all([initializeActions(), loadMeetings()]);
    setLoading(false);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  /**
   * Update actions array and other related states
   *
   * @param {array} actions - updated actions array
   * @param {string} newTitle - updated title
   * @param {number | null} meetingId - meeting ID
   */
  function updateActions(actions, newTitle, meetingId = null) {
    dispatch(updateTitle(newTitle));
    dispatch(setItens(actions));
    dispatch(setCurrentMeeting(meetingId));
  }

  /**
   * Fetch all actions from the API
   */
  async function getAllActions() {
    const { data, } = await listCompanyActions(company._id, {
      taskStatus: [TASK_STATUSES.IN_PROGRESS, TASK_STATUSES.CLOSED],
    });
    const filtered = data.actions;
    updateActions(filtered, 'Itens para revisão');
  }

  /**
   * Fetch quick actions from the API
   */
  async function getQuickActions() {
    const { data, } = await listCompanyActions(company._id, {
      isFast: true,
      taskStatus: [TASK_STATUSES.IN_PROGRESS],
    });
    const filtered = data.actions;
    updateActions(filtered, 'Itens de ação rapida');
  }

  /**
   * Fetch actions without meeting from the API
   */
  async function getActionsWithoutMeeting() {
    const { data, } = await notReunion(true);
    updateActions(data, 'Itens sem reunião');
  }

  /**
   * Fetch unassigned actions from the API
   */
  async function getUnassignedActions() {
    const { data, } = await getRiscoNotResponsible();
    updateActions(data, 'Itens sem resposavel');
  }

  /**
   * Handle the change of the current meeting
   *
   * @param {number} meetingId - meeting ID
   */
  const onCurrentMeetingChange = async (meetingId) => {
    const isValid = !(_.isString(meetingId) && meetingId.length === 0);
    if (isValid) {
      const { data, } = await getRiscoByReunionID(meetingId);
      updateActions(data, 'Itens de reunião', meetingId);
    } else {
      getAllActions();
    }
  }

  /**
   * Use effect to update filtered actions
   */
  useEffect(() => {
    const { values, } = INITIAL_VALUES[tabStatus];
    setFilteredActions(actions.filter((item) => {
      if(search != ''){
        return (item.resposta !== 'Aceitar') && values.includes(item.status) && item.problema.toLowerCase().includes(search.toLowerCase());
      }
      return (item.resposta !== 'Aceitar') && values.includes(item.status);
    }));
  }, [tabStatus, actions, loadingMatriz, search]);

  /**
   * Use effect to update actions badges
   */
  useEffect(() => {
    const updatedActionsStatuses = _.cloneDeep(actionsStatuses);
    for (const actionStatus of Object.values(updatedActionsStatuses)) {
      actionStatus.total = (actions.filter((item) => {
        return (item.resposta !== 'Aceitar') && actionStatus.values.includes(item.status);
      })).length;
    }
    setActionsStatuses(updatedActionsStatuses);
  }, [actions]);

  /**
   * Use effect to initialize required data
   */
  useEffect(() => {
    initialize();
  }, []);

  const MENU_FIXED = Object.freeze({
    noResponse: {
      id: 'noResponse',
      title: 'Itens sem resposta',
      icon: <></>,
      onClick: () => getUnassignedActions(),
    },
    noMeets: {
      id: 'noMeets',
      title: 'Itens sem reunião',
      icon: <></>,
      onClick: () => getActionsWithoutMeeting(),
    },
    fastAction: {
      id: 'fastAction',
      title: 'Ações rápidas',
      icon: <></>,
      onClick: () => getQuickActions(),
    },
    showAll: {
      id: 'showAll',
      title: 'Mostrar todos',
      icon: <></>,
      onClick: () => getAllActions(),
    },
  });


  return (
    <>
      {
        loading ?
          <Grid
            container
            xs={12}
            direction="row"
            justifyContent="center"
            style={{
              marginTop: 15,
            }}
          >
            <CircularProgress />
          </Grid> :
          <Container
            disableGutters
            maxWidth="xl"
            style={{
              marginBottom: '3rem',
            }}
          >
            <div>
        <TextField
            label="Pesquisar"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            style={{ background: "#fff" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon="search" />
                </InputAdornment>
              ),
            }}
          />
        </div>
            {
              filteredActions.length > 0 ?
                filteredActions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((action, index) => {
                  return (
                    <div
                      key={action.id}
                      style={{
                        marginBottom: '1rem',
                        marginTop: '0.5rem',
                      }}
                    >
                      <RiscAnalysisShow
                        data={action}
                        users={users}
                        reunions={meetings}
                      />
                    </div>
                  )
                }) :
                <Typography
                  variant="body1"
                  display="block"
                  align="center"
                  style={{
                    fontWeight: 500,
                    color: '#808080'
                  }}
                >
                  {`Você não possui ações com o estado: "${_.capitalize(t(`ACTION_STATUSES.${tabStatus}`))}"`}
                </Typography>
            }

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: filteredActions.length }]}
              component="div"
              SelectProps={{
                inputProps: {
                  'aria-label': 'Linhas',
                },
                native: true,
              }}
              labelRowsPerPage={`Linhas por página`}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              count={filteredActions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={() => <Paginator page={page + 1} rowsPerPage={rowsPerPage} count={filteredActions.length} onChange={handleChangePage} />}
            />

          </Container>
      }

      <PartialCustomMenuFixed menuFixed={MENU_FIXED}/>

      {/* <ActionsFilter
        todos={getAllActions}
        rapidas={getQuickActions}
        notReunion={getActionsWithoutMeeting}
        notResponse={getUnassignedActions}
      /> */}
    </>
  );
}
