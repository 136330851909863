import React from 'react';

import { makeStyles, } from '@material-ui/styles';

import TabPanel from './TabPanel';
import PhaseTable from './PhaseTable';
import DataMappingTabBar from './DataMappingTabBar';
import TabPanelContainer from './TabPanelContainer';

import DataMappingAnswerPanelContext from './context';
import DataMappingListMode from "./DataMappingListMode";
import SheetContext from "../../pages/MapeamentoDados/SheetContext";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DataMappingAnswerPanel() {
  const classes = useStyles();
  const {
    phases,
    selectedPhaseIdx,
    onChangeSelectedPhaseIdx,
  } = React.useContext(DataMappingAnswerPanelContext);
  let viewMode = React.useContext(SheetContext);
  if (viewMode?.hasOwnProperty('viewMode')) viewMode = viewMode.viewMode;

  return (
    <div className={classes.root}>
      {
        viewMode === 'table' ? <DataMappingTabBar
          phases={phases}
          onChange={onChangeSelectedPhaseIdx}
        /> : null
      }

      {
        viewMode === 'list' ?  <DataMappingListMode phases={phases} /> : phases
        .map((phase, index) => {
          return (
            <TabPanelContainer key={phase.id} >
              <TabPanel
                value={selectedPhaseIdx}
                index={index}
              >
                <PhaseTable phase={phase} />
              </TabPanel>
            </TabPanelContainer>
          )
        })
      }

    </div>
  );
}
