import React, { useState, } from 'react';
import { Notification, } from 'rsuite';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  CircularProgress,
  TableContainer,
  ListItemText,
} from '@material-ui/core';
import {makeStyles,} from '@material-ui/styles';

import NearMeIcon from '@material-ui/icons/NearMe';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import {
  changeUserActiveState,
  sendPasswordRecoveryEmail,
  deleteUser,
  changeUserFollowupEnabled,
} from '../../services/api';
import LimitedBackdrop from '../LimitedBackdrop';
import DeleteDialog from '../DeleteDialog';
import useModal from '../../hooks/useModal';


const useStyles = makeStyles({
  tableContainer: {
    maxWidth: '100%',
    height: '20rem',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.2rem',
      height: '0.4rem',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0, 0.2)',
      borderRadius: 2,
    },
  },
  headerCell: {
    color: '#54708C',
    fontFamily: 'Inter',
  },
  bodyCell: {
    color: '#1C265A',
    fontFamily: 'Inter',
  },
});

const INITIAL_USER = {
  _id: undefined,
  name: '',
};


function SimpleUsersTable({ users, getUsers, loading: loadingUsers, }) {
  const [loading, setLoading] = useState(false);
  const [currUser, setCurrUser] = useState(INITIAL_USER);
  const [deleteModal, openDeleteModal, closeDeleteModal] = useModal(setCurrentUser, clearCurrentUser);
  const classes = useStyles();

  function setCurrentUser(user) {
    setCurrUser(user);
  }

  function clearCurrentUser() {
    setCurrUser(INITIAL_USER);
  }


  /**
 * Send request to API to send password recovery email to user
 *
 * @param {object} user - user to send email
 */
  async function sendPasswordRecoveryLinkToUser(user) {
    setLoading(true);

    try {
      await sendPasswordRecoveryEmail(user.email);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'E-mail para recuperação de senha enviado com sucesso!',
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao enviar e-mail para recuperação de senha!',
        description: 'Tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Send request to API to set user active state to state
   *
   * @param {object} user - user to update state
   * @param {boolean} state - updated active state
   */
  async function toggleUserActiveState(user, state) {
    setLoading(true);

    const change = state ? 'ativar' : 'desativar';
    const changed = state ? 'ativado' : 'desativado';

    try {
      const { status, } = await changeUserActiveState(user._id, state);

      if (status === 204) {
        Notification['success']({
          title: `Usuário ${changed} com sucesso`,
          placement: 'bottomEnd',
        });
      } else {
        Notification['error']({
          title: `Falha ao ${change} usuário`,
          description: 'Tente novamente em alguns instantes.',
          placement: 'bottomEnd',
        });
      }

      getUsers();
    } catch {
      Notification['error']({
        title: `Erro ao ${change} o usuário`,
        description: 'Tente novamente em alguns instantes.',
        placement: 'bottomEnd',
      });
    }

    setLoading(false);
  }

  async function toggleFollowUpEnabled(user, enabled) {
    setLoading(true);

    const change = enabled ? 'ativar' : 'desativar';
    const changed = enabled ? 'ativadas' : 'desativadas';

    try {
      await changeUserFollowupEnabled(user._id, enabled);
      Notification['success']({
        title: `Notificações de follow-up ${changed} com sucesso`,
        placement: 'bottomEnd',
      });

      getUsers();
    } catch {
      Notification['error']({
        title: `Erro ao ${change} notificações de follow-up`,
        description: 'Tente novamente em alguns instantes.',
        placement: 'bottomEnd',
      });
    }

    setLoading(false);
  }

  async function handleDeleteUser() {
    if (!currUser._id) {
      return;
    }

    setLoading(true);

    try {
      const { status, } = await deleteUser(currUser._id);

      if (status === 204) {
        Notification['success']({
          title: 'Usuário deletado com sucesso',
          placement: 'bottomEnd',
        });

        getUsers();
        closeDeleteModal();
      } else {
        Notification['error']({
          title: 'Falha ao deletar usuário',
          placement: 'bottomEnd',
        });
      }
    } catch {
      Notification['error']({
        title: 'Um erro ocorreu e o usuário não pôde ser deletado',
        description: 'Tente novamente em alguns instantes.',
        placement: 'bottomEnd',
      });
    }

    setLoading(false);
  }


  return (
    <>
      <LimitedBackdrop open={loading || loadingUsers}>
        <CircularProgress />
      </LimitedBackdrop>

      <DeleteDialog
        open={deleteModal}
        confirmationMessage={`Tem certeza que deseja excluir o usuário ${currUser.name}?`}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteUser}
      />

      <TableContainer className={classes.tableContainer}>
        <Table
          responsive
          stickyHeader
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                className={classes.headerCell}
              >
                Usuário
              </TableCell>

              <TableCell
                padding="checkbox"
                className={classes.headerCell}
              >
                Ativo
              </TableCell>

              <TableCell
                padding="checkbox"
                className={classes.headerCell}
              >
                Follow-up
              </TableCell>

              <TableCell
                padding="checkbox"
                className={classes.headerCell}
              >
                Enviar senha
              </TableCell>

              <TableCell
                padding="checkbox"
                className={classes.headerCell}
                align="right"
              >
                Deletar
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users ?
              users.map((user) => {
                return (
                  <TableRow>
                    <TableCell className={classes.bodyCell}>
                      <ListItemText
                        primary={user.name}
                        secondary={user.email}
                      />
                    </TableCell>

                    <TableCell
                      padding="checkbox"
                      className={classes.bodyCell}
                    >
                      <Checkbox
                        checked={user.ativo}
                        onChange={(event) => toggleUserActiveState(user, event.target.checked)}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                    </TableCell>

                    <TableCell
                      padding="checkbox"
                      className={classes.bodyCell}
                    >
                      <Checkbox
                        checked={user.followupEnabled}
                        onChange={(event) => toggleFollowUpEnabled(user, event.target.checked)}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => sendPasswordRecoveryLinkToUser(user)}
                      >
                        <NearMeIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => openDeleteModal(user)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }) :
              null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SimpleUsersTable;
