import {Box, Button, DialogActions, Menu, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {MAPPING_FIELD_TYPES} from "../../data/constants";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";


const filters = [
  MAPPING_FIELD_TYPES.SECTOR.typePre,
]

function FilterForm({field, onForm}) {

  const [op, setOp] = useState('Equal');
  const [values, setValues] = useState([]);

  useEffect(() => {
    onForm({
      fieldId: field.id,
      field: field.typePre,
      op,
      values,
      items: field.options.filter((option) => values.includes(option.id))
    });
  }, [op, values]);

  if (field.typePre === MAPPING_FIELD_TYPES.SECTOR.typePre) {

    function onChange(value) {
      if (values.includes(value)) {
        setValues(values.filter((v) => v !== value));
      } else {
        setValues([...values, value]);
      }
    }

    return (
      <>
        {/*<Select label="Operação" value={op} onChange={(event) => setOp(event.target.value)} fullWidth sx={{mt: 2}}>*/}
        {/*  <MenuItem value='Equal'>Igual</MenuItem>*/}
        {/*  <MenuItem value='NotEqual'>Diferente</MenuItem>*/}
        {/*</Select>*/}
        {
          field.options.map((option) => {
            return (
              <MenuItem selected={values.includes(option.id)} key={option.id} value={option.id}
                        onClick={() => onChange(option.id)}>
                {option.title}
              </MenuItem>
            );
          })
        }
      </>
    );
  } else {
    return (
      'Sem Filtros disponiveis'
    );
  }
}


/**
 *
 * @param anchorEl {HTMLElement}
 * @param field
 * @param onFilter {(a: {field: string, op: string, value: any}) => void}
 * @returns {Element}
 * @constructor
 */
export default function DataMappingFilterDialog({field, onFilter}) {

  const [form, setForm] = useState({field: field.typePre, op: 'EQUAL', value: []});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function close() {
    setAnchorEl(null);
  }

  function confirm() {
    if (form.values) {
      if (Array.isArray(form.values)) {
        if (form.values.length) {
          onFilter(form);
        }
      } else {
        onFilter(form);
        onFilter(form);
      }
    }
    close();
  }

  function onIconClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return filters.includes(field.typePre) ? <>
      <FilterListIcon onClick={onIconClick} fontSize="small" style={{color: 'white', cursor: 'pointer'}}/>
      <Menu open={open} anchorEl={anchorEl} onClose={confirm} sx={{p: 2}}>
        <Box style={{width: 500,}} sx={{padding: 1}}>
          <Typography variant="h5">Filtro do {field.name}</Typography>
          <FilterForm onForm={setForm} field={field}/>
          <DialogActions>
            <Button onClick={close}>Cancelar</Button>
          </DialogActions>
        </Box>
      </Menu>
    </> : <></>
}
