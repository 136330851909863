import React, { useEffect, useState } from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import { Checkbox, Chip, FormControlLabel, Grid, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Dialog from '../Dialog';
import HeatMapDataMappingPanel from './HeatMapDataMappingPanel';
import ModalRanking from '../CorpoPlano/new.plano/ModalRanking';

import { listCompanyMeetings, getUsers } from '../../services/api';

import Risk, { RISK_STATUSES, } from '../../core/entities/risk';
import useModal from '../../hooks/useModal';


const INITIAL_RISK_STATUS_COUNT = Object.freeze({
  [RISK_STATUSES.LOW.key]: 0,
  [RISK_STATUSES.MEDIUM.key]: 0,
  [RISK_STATUSES.HIGH.key]: 0,
});


function RiskStatusSummary({ countMap, }) {
  const { t, } = useTranslation();


  return (
    <Grid display="flex" sx={{ mr: 1 }}>
      {
        Object.keys(countMap).map((key) => {
          const count = countMap[key];
          const risk = RISK_STATUSES[key];

          return (
            <Grid key={key} display="flex" style={{ marginLeft: 5 }} >
              <div style={{height: '20px', width: '20px', backgroundColor: risk.color, borderRadius: '3px', marginLeft: '1rem', marginRight: '1rem',}}/>
              <span>
                {
                  t('PAGES.HEAT_MAP.item_in_risk_status', {
                    count,
                    risk: t(`RISK_STATUSES.${risk.key}`).toLowerCase(),
                  })
                }
              </span>
            </Grid>
          );
        })
      }
    </Grid>
  );
}


const useStyles = makeStyles({
  riskContainer: {
    '&::-webkit-scrollbar': {
      width: '0.2rem',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0, 0.2)',
      borderRadius: 2,
    },
  },
});

export default function HeatMap({ diagnosisItems, dataMappingItems, styles }) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [users, setUsers] = useState([]);
  const [reunions, setReunios] = useState([]);
  const [modalList, setModalList] = useState([]);
  const [open, openModal, closeModal] = useModal();
  const [dataMappingOpen, openDataMappingModal, closeDataMappingModal] = useModal();
  const [dataMappingModalItems, setDataMappingModalItems] = useState([]);
  const [riskStatusCount, setRiskStatusCount] = useState({
    diagnosis: Object.assign({}, INITIAL_RISK_STATUS_COUNT),
    mapping: Object.assign({}, INITIAL_RISK_STATUS_COUNT),
  });
  const [enabled, setEnabled] = useState({
    diagnosis: true,
    mapping: true,
  });
  const [heatMapData, setHeatMapData] = useState({
    diagnosis: {
      items: [],
      color: '#1C265A',
    },
    mapping: {
      items: [],
      borderRadius: 5,
      color: '#7B61FF',
    },
  });


  useEffect(function updateData() {
    setHeatMapData({
      diagnosis: {
        ...heatMapData.diagnosis,
        items: diagnosisItems,
      },
      mapping: {
        ...heatMapData.mapping,
        items: dataMappingItems,
      },
    });
  }, [diagnosisItems, dataMappingItems]);

  useEffect(function updateRiskStatusCount() {
    const updatedRiskStatusCount = {};

    for (const [key, value] of Object.entries(heatMapData)) {
      const keyCount = Object.assign({}, INITIAL_RISK_STATUS_COUNT);
      for (const item of value.items) {
        const risk = Risk.getRisk(item.probabilidade, item.impacto);
        if (undefined != keyCount[risk.key]) {
          keyCount[risk.key] += 1;
        }
      }

      updatedRiskStatusCount[key] = keyCount;
    }

    setRiskStatusCount(updatedRiskStatusCount);
  }, [heatMapData]);

  async function getData(list) {
    try {
      await Promise.all([
        getUsers().then(({ data }) => setUsers(data)),
        listCompanyMeetings().then(({ data }) => setReunios(data))
      ]);
    } catch {
    }

    openModal();
    setModalList(list);
  }

  function onShowDataMappingRiskItems(list = []) {
    setDataMappingModalItems(list.map((i) => i.lineId));
    openDataMappingModal();
  }

  function onChangeEnabled(ev) {
    setEnabled({
      ...enabled,
      [ev.target.name]: ev.target.checked,
    });
  }


  return (
    <div styles={styles}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {
          Object.entries(heatMapData).map(([key, { color, }]) => {
            return (
              <FormControlLabel
                key={key}
                control={(
                  <Checkbox
                    name={key}
                    onChange={onChangeEnabled}
                    checked={enabled[key]}
                    style={{
                      color,
                    }}
                  />
                )}
                label={_.capitalize(t(key, { count: 1, }))}
              />
            );
          })
        }
      </Grid>

      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '10%',
            height: '490px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          {
            ['Muito Alto -', 'Alto -', 'Moderado -', 'Baixo -', 'Muito Baixo -']
              .map((t) => (<span key={t}>{t}</span>))
          }

          <span
            style={{
              fontWeight: 'bold',
              transform: 'rotate(-90deg)',
              letterSpacing: '1px',
              marginRight: "-20px"
            }}
          >
            Probabilidade
          </span>
        </div>

        <div
          style={{
            borderLeft: '2px solid black',
            borderBottom: '2px solid black',
            height: '500px',
            width: '90%',
            padding: '10px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {
              [1, 2, 3, 4, 5].map((probability) => {
                return (
                  <div
                    key={probability}
                    style={{
                      flex: '2',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                      padding: '0 2px',
                    }}
                  >
                    {
                      [5, 4, 3, 2, 1].map((impact) => {
                        // @aqui
                        const risk = Risk.getRisk(probability, impact);
                        const riskItems = Object.fromEntries(Object.entries(heatMapData)
                          .map(([key, i]) => {
                            return  [key, enabled[key] ? i.items.filter((item) => {
                              return (item.probabilidade == probability) &&
                                (item.impacto == impact);
                            }): []];
                          }));

                        return (
                          <div
                            key={impact}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor: risk.color,
                              width: '100%',
                              minHeight: '19.4%',
                              maxHeight: '19.4%',
                              padding: '0.2rem',
                            }}
                          >
                            <span
                              style={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '16px',
                              }}
                            >
                              {_.capitalize(t(`RISK_STATUSES.${risk.key}`))}
                            </span>
                            <div
                              className={classes.riskContainer}
                              style={{
                                display: 'flex',
                                flexFlow: 'wrap',
                                maxWidth: '100%',
                                overflowY: 'auto',
                                justifyContent: 'flex-start',
                              }}
                            >
                              {
                                Object.entries(heatMapData)
                                  .flatMap(([key, { color, borderRadius, }]) => {
                                    const filteredItems = riskItems[key];

                                    const RiskItemChip = (item) => {
                                      return (
                                        <Chip
                                          onClick={() => {
                                            const callback = ('mapping' != key) ? getData :
                                              onShowDataMappingRiskItems;
                                            callback(filteredItems);
                                          }}
                                          key={`${key}-${item.id}`}
                                          style={{
                                            borderRadius,
                                            color: 'white',
                                            marginRight: '2px',
                                            marginBottom: '2px',
                                            backgroundColor: color,
                                            height: '18px',
                                            fontSize: 10,
                                          }}
                                          label={item.ordem}
                                          size="small"
                                        />
                                      );
                                    }

                                    return filteredItems.map(RiskItemChip);
                                  })
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          width: '90%',
          float: 'right',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Impacto
        </span>

        <div
          style={{
            display: 'flex',
            width: '80%',
            float: 'right',
            justifyContent: 'space-between',
          }}
        >
          {
            ['10%', '30%', '50%', '70%', '90%'].map((t) => (<span key={t}>{t}</span>))
          }
        </div>
      </div>

      <Grid
        container
        justifyContent="flex-end"
        style={{
          color: 'gray',
          fontWeight: 'bold',
          fontSize: '0.75rem',
        }}
      >
        *Clique no link em cada retângulo para verificar as tarefas classificadas em cada risco
      </Grid>

      <Grid
        container
        display="flex"
        style={{
          color: 'gray',
          fontWeight: 'bold',
        }}
      >
        {
          Object.entries(riskStatusCount).map(([key, statusCount]) => {
            return (
              <Grid display="flex" key={key} style={{display: enabled[key] ? undefined : 'none', marginRight: '0.3rem',}}>
                <Typography sx={{width: 100}}>{_.capitalize(t(key, { count: 1, }))}</Typography>
                <RiskStatusSummary countMap={statusCount} />
              </Grid>
            );
          })
        }
      </Grid>

      <ModalRanking
        isOpen={open}
        onRequestClose={closeModal}
        body={modalList}
        reunionUnique={reunions}
        users={users}
      />

      <Dialog
        maxWidth="xl"
        open={dataMappingOpen}
        onClose={closeDataMappingModal}
        title={_.capitalize(t('data_mapping'))}
        PaperProps={{
          style: {
            width: '1000px',
            maxWidth: '90%',
          },
        }}
      >
        <HeatMapDataMappingPanel items={dataMappingModalItems} />
      </Dialog>
    </div>
  );
}
