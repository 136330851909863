import React from "react";
import Paginator from "../Paginator/Paginator";
import { TablePagination } from "@material-ui/core";

export default function CustomPaginator({data, rowsPerPage = 5, page = 1, onPageChange = () => { }, onRowsPerPageChange = () => { }}) {
  return (
    <TablePagination
      rowsPerPageOptions={[
        5,
        10,
        25,
        { label: 'Todos', value: data.length },
      ]}
      component="div"
      count={data.length}
      SelectProps={{
        inputProps: { 'aria-label': 'Linhas' },
        native: true,
      }}
      labelRowsPerPage={`Linhas por página`}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      rowsPerPage={rowsPerPage}
      page={page-1}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={() => <Paginator page={page} rowsPerPage={rowsPerPage} count={data.length} onChange={onPageChange} />}
    />
  )

}