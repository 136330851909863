import React, { useEffect, useState, memo, useMemo, } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useSelector, } from 'react-redux';

import Container from '@material-ui/core/Container';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { Notification, } from 'rsuite';

import Table from '../../components/Table';
import SearchField from '../../components/SearchField';
import UserOnlineStateBadge from './UserOnlineStateBadge';
import ModalConfigUser from '../../pages/DashboardMaster/modalConfigUsers';

import api, { getHistoricoLogins, getUserCompanies, } from '../../services/api';

import { checkSearch, } from '../../utils';
import { selectUsersMap, } from '../../features/usersSlice';
import { selectGroupsMap, } from '../../features/groupsSlice';


const DEFAULT_USER = Object.freeze({
  _id: undefined,
  name: '',
  group: '',
  empresa: '',
});

const HEADERS = Object.freeze([
  {
    //! Used to represent the online/offline state
    label: '',
    align: 'center',
  },
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'Grupo',
    align: 'center',
  },
  {
    label: 'Último acesso',
    align: 'center',
  },
  {
    label: 'IP',
    align: 'center',
  },
  {
    label: 'Acessos',
    align: 'center',
  },
  {
    label: 'Empresas ativas',
    align: 'center',
  },
  {
    label: 'Configurações',
    align: 'center',
  },
  {
    label: 'Ativo',
    align: 'center',
  },
  {
    label: 'Acessar',
    align: 'center',
  },
]);

function UserActiveCompaniesCount({ userId, }) {
  const [count, setCount] = useState(undefined);
  const [loading, setLoading] = useState(true);


  async function updateCompaniesCount() {
    setLoading(true);
    try {
      const { data, } = await getUserCompanies(userId);
      setCount(data.companies.length);
    } catch (error) {
      console.error(error);
      setCount(undefined);
    }
    setLoading(false);
  }

  useEffect(() => {
    updateCompaniesCount();
  }, []);


  return (
    <>
      {
        loading ?
        <CircularProgress size={15} /> :
        undefined != count ?
        count :
        '-'
      }
    </>
  );
}


/**
 * Pane used to show access ranking data in the admin console
 *
 * @returns Rendered pane
 */
function LoginRankTable() {
  const usersMap = useSelector(selectUsersMap);
  const groupsMap = useSelector(selectGroupsMap);
  const [loading, setLoading] = useState(true);
  const [loginItems, setLoginItems] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [currUserId, setCurrUserId] = useState('');
  const currUser = useMemo(() => {
    return usersMap[currUserId] || {
      ...DEFAULT_USER,
      _id: currUserId,
    };
  }, [currUserId, usersMap]);
  const openConfigModal = useMemo(() => currUserId != '', [currUserId]);
  const filteredLoginItems = useMemo(() => {
    if (searchString.length > 0) {
      return loginItems.filter((loginItem) => checkSearch(loginItem.name, searchString));
    } else {
      return loginItems;
    }
  }, [loginItems, searchString]);

  /**
   * Fetch required data from the API
   */
  const loadData = async () => {
    setLoading(true);

    try {
      const { data, } = await getHistoricoLogins();
      setLoginItems(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar usuários do servidor!',
      });
    }

    setLoading(false);
  }

  async function onOpenConfigModal(userId) {
    setCurrUserId(userId);
  }

  /**
   * Close config modal and clears user to be deleted data
   */
  const onCloseConfigModal = () => {
    setCurrUserId('');
  }

  /**
   * Configure local info to access system as the received user
   *
   * @param {object} user - user to represent
   */
  const accessUser = async (user) => {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Funcionalidade não implementada!',
    });
  }

  /**
   * Toggle user active status in API
   *
   * @param {object} loginHistoryItem - login history item that contains user info
   */
  const toggleUserActiveState = async (loginHistoryItem) => {
    setLoading(true);

    const { id_usuario: userId, ativo: isActive, } = loginHistoryItem;
    try {
      const { status, } = await api.post(`/user/${userId}/change-active-state`, {
        active: !isActive,
      });

      if (status === 204) {
        Notification['success']({
          placement: 'bottomEnd',
          title: `Usuário ${isActive ? 'inativado' : 'ativado'} com sucesso!`,
        });

        const updatedLoginItems = _.cloneDeep(loginItems);
        const updatedLoginItem = updatedLoginItems.find(({ id_usuario, }) => id_usuario === userId);
        if (updatedLoginItem != undefined) {
          updatedLoginItem.ativo = !isActive;
        }
        setLoginItems(updatedLoginItems);
      } else {
        Notification['warning']({
          placement: 'bottomEnd',
          title: `Não foi possível ${isActive ? 'inativar' : 'ativar'} o usuário!`,
          description: 'Tente novamente dentro de alguns instantes.',
        });
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao ${isActive ? 'inativar' : 'ativar'} usuário!`,
        description: 'Tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Use effect to initialize required data
   */
  useEffect(() => {
    loadData()
  }, []);


  return (
    <Container
      maxWidth="xl"
      component={Paper}
    >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: 15,
        paddingTop: 15,
      }}
    >
      <SearchField onChange={(value) => setSearchString(value || '')} />
    </div>

      <Table
        headers={HEADERS}
        data={filteredLoginItems}
        loading={loading}
        errorMessage="Falha ao carregar ranking de acessos"
        noDataMessage="Nenhum ranking de acesso encontrado"
      >
        {
          (loginItem, props) => {
            const user = usersMap[loginItem.id_usuario] || {};
            const group = groupsMap[user.group] || {};

            return (
              <TableRow {...props}>
                <TableCell align="center">
                  <UserOnlineStateBadge userId={loginItem.id_usuario} />
                </TableCell>
                <TableCell align="center">
                  { loginItem.name }
                </TableCell>
                <TableCell align="center">
                  { group.name || '-' }
                </TableCell>
                <TableCell align="center">
                  { moment(loginItem.last_update).calendar() }
                </TableCell>
                <TableCell align="center">
                  { loginItem.ip }
                </TableCell>
                <TableCell align="center">
                  { loginItem.contador }
                </TableCell>
                <TableCell align="center">
                  {
                    undefined != user._id ?
                    <UserActiveCompaniesCount userId={user._id} /> :
                    '-'
                  }
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onOpenConfigModal(loginItem.id_usuario)} >
                    <SettingsIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => toggleUserActiveState(loginItem)} >
                    { loginItem.ativo ? <CheckedIcon /> : <UncheckedIcon /> }
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => accessUser(loginItem)}
                    color="primary"
                    variant="outlined"
                    disableElevation
                    size="small"
                  >
                    Acessar
                  </Button>
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>

      <ModalConfigUser
        open={openConfigModal}
        onClose={onCloseConfigModal}
        infoUsers={currUser}
        logs={loginItems}
      />
    </Container>
  );
}

export default memo(LoginRankTable);
