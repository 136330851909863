import React from 'react';
import {createRoot} from 'react-dom/client';

import {ThemeProvider, createTheme, responsiveFontSizes} from '@material-ui/core/styles';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const theme = responsiveFontSizes(createTheme({

}));

const root = createRoot(document.getElementById('root'));

root.render(
<div className="App">
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <App/>
    </ThemeProvider>
  </React.StrictMode>
</div>
);
