import {useCallback, useState} from "react";

/**
 * @template T
 * @param asyncFn {function(T): Promise<T>}
 * @param defaultValue {T}
 * @returns {[T, boolean, (function(...T): Promise<void>), Error]}
 */
export default function useAsyncState(asyncFn, defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (...args) => {
    try {
      setLoading(true);
      await asyncFn(...args);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [value, loading, error, asyncFn]);

  return [value, loading, request, error];
}
