import { useState } from "react";
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import {Form, Button, Col, Row} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

import ToastAnimated, { showToast } from "../../ui-lib/toast";
import "react-toastify/dist/ReactToastify.css";

import api from "../../services/api";
import { DEFAULT_QUESTION_CATEGORIES } from "../../utils/constants";


function CreateTemplateQuestion() {
  const { t, } = useTranslation();
  const [data, setData] = useState({
    problem: '',
    action: '',
    sector: '',
    risk: '',
    category: '',
  });


  function handleChange(ev) {
    setData({
      ...data,
      [ev.target.name]: ev.target.value,
    });
  }

  const handleClick = () => showToast({ type: "success", message: "Cancelado com sucesso" });

  function handleCreateQuestion(event) {
    event.preventDefault();

    api.post("/perguntas/template", {
        ordem: 1,
        problema: data.problem,
        setor: data.sector,
        resposta: 0,
        acao: data.action,
        risco: data.risk,
        category: data.category,
      })
      .then((response) => {
        return showToast({
          type: "success",
          message: "Tarefa criada",
          pauseOnFocusLoss: true,
        });
      })
      .catch((response) => {
        return showToast({
          type: "error",
          message: "Erro ao criar a tarefa",
        });
      });
  }


  return (
    <Container component={Paper}>
      <br />
      <Form>
        <Form.Group controlId="textarea">
          <Form.Label>
            {_.capitalize(t('question'))}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={data.problem}
            name="problem"
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="textarea">
          <Form.Label>
            {_.capitalize(t('risk_consequence'))}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={data.risk}
            name="risk"
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="textarea">
          <Form.Label>
            {_.capitalize(t('action'))}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={data.action}
            name="action"
            onChange={handleChange}
          />
        </Form.Group>

        <Row>
          <Form.Group as={Col}>
            <Form.Label>
              {_.capitalize(t('sector'))}
            </Form.Label>
            <Form.Control
              as="select"
              defaultValue={_.capitalize(t('PAGES.EDIT_QUESTION_TEMPLATE.choose_a_sector'))}
              value={data.sector}
              name="sector"
              onChange={handleChange}
            >
              <option>Escolha um setor</option>
              <option>Administrativo</option>
              <option>Juridico</option>
              <option>TI</option>
              <option>RH</option>
              <option>Outro</option>
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col}>
            <Form.Label>
              {_.capitalize(t('category'))}
            </Form.Label>
            <Form.Control
              as="select"
              defaultValue={_.capitalize(t('PAGES.CREATE_QUESTION_TEMPLATE.choose_a_category'))}
              value={data.category}
              name="category"
              onChange={handleChange}
            >
              <option>Escolha uma categoria</option>
              {
                DEFAULT_QUESTION_CATEGORIES.map((category) => (
                  <option key={category}>
                    { category }
                  </option>
                ))
              }
            </Form.Control>
          </Form.Group>
        </Row>

        <div className="mb-2">
          <ToastAnimated />
          <Button
            onClick={handleCreateQuestion}
            variant="success"
            size="lg"
          >
            {_.capitalize(t('send'))}
          </Button>{" "}
          <Button
            onClick={handleClick}
            variant="secondary"
            size="lg"
          >
            {_.capitalize(t('cancel'))}
          </Button>
        </div>
      </Form>
      <br />
    </Container>
  );
}

export default CreateTemplateQuestion;
