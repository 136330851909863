import { memo, useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import HeatMap from './Components/HeatMap';
import ActionsByRisk from '../ActionsByRisk';

import { selectCompany } from '../../features/companySlice';
import { getDataMappingRiskAnalysis } from '../../services/api';

/**
 * Component to show risc analysis data
 */
function RiscContainerShow() {
  const company = useSelector(selectCompany);
  const actions = useSelector((state) => state.matriz.value);
  const [dataMappingItems, setDataMappingItems] = useState([]);
  const unfinishedActions = actions.filter((item) => item.status !== 'Concluido');


  async function initialize() {
    try {
      const { data, } = await getDataMappingRiskAnalysis(company._id);
      setDataMappingItems(data.items);
    } catch {
      setDataMappingItems([]);
    }
  }

  useEffect(() => {
    initialize();
  }, []);


  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      xs={12}
    >
      <Grid
        container
        item
        xs={12}
      >
        <HeatMap
          diagnosisItems={unfinishedActions}
          dataMappingItems={dataMappingItems}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
      >
        <ActionsByRisk />
      </Grid>
    </Grid>
  );
}

export default memo(RiscContainerShow);
