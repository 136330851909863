import React from 'react';
import { makeStyles, } from '@material-ui/styles';
import TextInput from './TextInput';
import Tooltip from '../MapaDados/Tooltip';
import DataMappingFilterDialog from "../DataMappingAnswerPanel/DataMappingFilterDialog";
import FilterListIcon from "@mui/icons-material/FilterList";


const useStyles = makeStyles({
  columnContainer: {
    marginRight: '0.5rem',
    width: '180px',
  },
  columnTitleContainer: {
    background: '#54708C',
    padding: '1px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderRadius: 5,
    height: '50px',
    marginBottom: '0.5rem',
  },
  columnTitle: {
    color: '#fff',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
});


const Coluna = ({ data, mapaDados, page, rowsPerPage, onAddFilter}) => {
  const classes = useStyles();

  const rows = mapaDados.map((resposta) => {
    return (
      <TextInput
        key={resposta.uid}
        uid={resposta.uid}
        field={data}
      />
    );
  });


  return (
    <div className={classes.columnContainer}>
      <div
        className={classes.columnTitleContainer}
        style={{ color: 'white', display: 'flex', flexGrow: 1 }}
      >
        {
          (data.description != undefined) && (data.description != '') ?
          <Tooltip
            arrow
            title={ data.description }
            PopperProps={{
              modifiers: {
                offset: {
                  enabled: true,
                  offset: '0px, 0px',
                }
              }
            }}
            placement="top"
          >
            <strong style={{flex: 1, }}>
              { data.name }
            </strong>
          </Tooltip> :
          <strong style={{flex: 1, }}>
            { data.name }
          </strong>
        }
        <DataMappingFilterDialog field={data} onFilter={onAddFilter}/>

      </div>

      { rowsPerPage != null ?
        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) :
        rows
      }
    </div>
  )
}

export default Coluna;
