import { useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';

import { listCompanyMeetings, } from '../../services/api';
import { selectUsers, } from '../../features/usersSlice';

import CorpoResposta from './index';


export default function ActionList({ questions, }) {
  const users = useSelector(selectUsers);
  const [meetings, setMeetings] = useState([]);


  /**
   * Fetch component state from the API
   */
  async function getData() {
    const { data: _meetings, } = await listCompanyMeetings();
    setMeetings(_meetings);
  }

  useEffect(() => {
    getData();
  }, []);


  return questions.map((question) => {
    return (
      <CorpoResposta
        key={question.id}
        data={question}
        users={users}
        meetings={meetings}
      />
    );
  });
}
